import React, { useState } from "react";
import { encode as base64_encode } from 'base-64';
import bcrypt from 'bcryptjs';
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import CircularProgress from "@material-ui/core/CircularProgress"; // Adicionado para o spinner
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as reduxActions from "store/actions";
import Email from "@material-ui/icons/Email";
import Button from "components/CustomButtons/Button";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardFooter from "components/Card/CardFooter";
import CardHeader from "components/Card/CardHeader";
import CustomInput from "components/CustomInput/CustomInput";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import IconButton from "components/CustomButtons/IconButton";
import TextField from "components/TextField";
import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle";
import apims from "apims/";
import { translate, appAlert, customApp } from "functions/";
require('dotenv').config();

const LKEY = process.env.REACT_APP_PROD === "true"
  ? process.env.REACT_APP_KP
  : process.env.REACT_APP_KL;

const useStyles = makeStyles(styles);

function LoginPage(props) {
  const [state, setState] = useState({
    email: '',
    password: '',
    token: '',
    reqTwoFactor: false,
    loginToken: null,
    isLoading: false,
  });

  const classes = useStyles();

  const handleChange = (field, value) => {
    setState((prev) => ({ ...prev, [field]: value }));
  };

  const showAlert = (variant, messageKey) => {
    props.callback({
      fn: 'alert',
      variant,
      horizontal: "center",
      message: translate(messageKey, 1),
    });
  };

  const emailLogin = async () => {
    const { email, password } = state;

    if (!email) {
      return showAlert('warning', '$__alertLoginEmptyEmail');
    }
    if (!password) {
      return showAlert('warning', '$__alertLoginEmptyPassword');
    }

    setState((prev) => ({ ...prev, isLoading: true }));

    try {
      const hashedPassword = await bcrypt.hash(password, 10);
      const payload = base64_encode(
        base64_encode(
          JSON.stringify({
            lp: base64_encode(`${hashedPassword}//${LKEY}`),
            login: email,
            password: base64_encode(`${hashedPassword}///${base64_encode(password)}///${hashedPassword}`),
          })
        )
      );

      const res = await apims.post('/Login', payload);
      handleLoginResponse(res);

    } catch (error) {
      console.error(error);
      showAlert('warning', '$__alertServerConectionLogin');
      setState((prev) => ({ ...prev, isLoading: false }));
    } finally {
    }
  };

  const handleLoginResponse = (res) => {
    if (res?.data?.code === 400) {
      showAlert('warning', `$__${res.data.error}`);
    } else if (res?.data?.twoFactor) {
      setState((prev) => ({ ...prev, reqTwoFactor: true, loginToken: res.data.token }));
    } else if (res?.data?.token) {
      localStorage.setItem('TOKEN', res.data.token);
      props.callback({ fn: 'login', token: res.data.token });
    }
  };

  const validateToken = async () => {
    const { token, loginToken } = state;

    if (!token || token.length !== 6) {
      return showAlert('warning', '$__invalidToken');
    }

    setState((prev) => ({ ...prev, isLoading: true }));

    try {
      const res = await apims.post('/M_User_TwoFactor', {
        action: 'verify',
        code: token,
        token: loginToken,
      });

      if (res?.data?.result) {
        localStorage.setItem('TOKEN', loginToken);
        props.callback({ fn: 'login', token: loginToken });
      } else {
        showAlert('warning', '$__invalidToken');
      }
    } catch (error) {
      console.error(error);
    } finally {
      setState((prev) => ({ ...prev, token: '', isLoading: false }));
    }
  };

  const recoveryPassword = async (email) => {
    if (!email) {
      return showAlert('warning', '$__loginAlertRecoveryEmailEmpty');
    }

    try {
      const res = await apims.get(`/M_User_RecoveryPass/?email=${email}`);
      if (res?.data) {
        showAlert('success', '$__loginAlertRecoveryEmailSended');
      } else {
        showAlert('warning', '$__loginAlertRecoveryInvalidEmail');
      }
    } catch (error) {
      console.error(error);
      showAlert('error', '$__loginAlertRecoveryError');
    }
  };

  return (
    <div className={classes.container}>
      <GridContainer style={{ display: "flex", justifyContent: "center" }}>
        <GridItem xl={4} lg={4} md={4} sm={12} xs={12}>
          <Card login>
            <CardHeader className={`${classes.cardHeader} ${classes.textCenter}`} color="primary">
              <h4 className={classes.cardTitle}>{translate('$__login')}</h4>
            </CardHeader>
            <CardBody>
              {!state.reqTwoFactor ? (
                <>
                  <CustomInput
                    labelText={translate('$__email')}
                    id="email"
                    formControlProps={{ fullWidth: true }}
                    inputProps={{
                      onChange: (e) => handleChange('email', e.target.value),
                      endAdornment: (
                        <InputAdornment position="end">
                          <Email className={classes.inputAdornmentIcon} />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <CustomInput
                    labelText={translate('$__password')}
                    id="password"
                    formControlProps={{ fullWidth: true }}
                    inputProps={{
                      onChange: (e) => handleChange('password', e.target.value),
                      onKeyDown: (e) => e.keyCode === 13 && emailLogin(),
                      endAdornment: (
                        <InputAdornment position="end">
                          <Icon className={classes.inputAdornmentIcon}>lock_outline</Icon>
                        </InputAdornment>
                      ),
                      type: "password",
                      autoComplete: "off",
                    }}
                  />
                  <IconButton
                    icon="lock"
                    text={translate('$__recoveryPass', '*')}
                    reverse
                    style={{ float: 'right' }}
                    onClick={() => recoveryPassword(state.email)}
                  />
                </>
              ) : (
                <TextField
                  labelText="Token"
                  value={state.token}
                  formControlProps={{ fullWidth: true }}
                  inputProps={{
                    onChange: (e) => handleChange('token', e.target.value),
                    type: "number",
                  }}
                />
              )}
            </CardBody>
            <CardFooter className={classes.justifyContentCenter}>
              <Button
                style={{
                  backgroundColor: state.isLoading ? 'gray' : customApp("menu"),
                  color: "#FFF",
                  position: "relative",
                  height: 50,
                  width: 120
                }}
                simple
                size="lg"
                block
                onClick={state.reqTwoFactor ? validateToken : emailLogin}
                disabled={state.isLoading}
              >
                {state.isLoading ? (
                  <CircularProgress
                    size={24}
                    style={{
                      color: "#FFF",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: -12,
                      marginLeft: -12,
                    }}
                  />
                ) : (
                  translate(state.reqTwoFactor ? '$__validateTokenAndSigin' : '$__sigin')
                )}
              </Button>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}

const mapStateToProps = () => ({});
const mapDispatchToProps = (dispatch) => bindActionCreators(reduxActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);