import React from "react";
import { useSelector } from "react-redux";

// COMPONENTS
import Checklist from "components/Checklist";
import KPIChange from "components/KPI/change";

// FUNCTIONS
import { translate, customApp } from "functions/";

// IMAGES
import defaultAvatar from "assets/img/fundo-sonne.png";
import MachenIco from "assets/icons/machen.svg";

// Função para remover tags HTML
function stripHTML(html) {
    return html.replace(/<\/?[^>]+(>|$)/g, ""); // Regex que remove tags HTML
}

function ExpandedCard(props) {
    const { cards: db, session } = useSelector((store) => store.db);
    const data = db[props.cardId || props.data._id] || {};

    const [ImagePreview, setImagePreview] = React.useState(true);

    const renderYoutubeOrVimeo = () => {
        if (data.url?.includes("youtube.com") || data.url?.includes("youtu.be")) {
            const videoId = data.url.includes("youtube.com")
                ? new URL(data.url).searchParams.get("v")
                : data.url.split("/").pop();
            return (
                <iframe
                    width="100%"
                    height="100%"
                    style={{ border: "none" }}
                    src={`https://www.youtube.com/embed/${videoId}`}
                    allow="autoplay; encrypted-media"
                    allowFullScreen
                />
            );
        }

        if (data.url?.includes("vimeo.com")) {
            const videoId = data.url.split("/").pop();
            return (
                <iframe
                    width="100%"
                    height="100%"
                    style={{ border: "none" }}
                    src={`https://player.vimeo.com/video/${videoId}`}
                    allow="autoplay; fullscreen"
                    allowFullScreen
                />
            );
        }

        return null;
    };

    const hasYoutube = !!renderYoutubeOrVimeo();

    return (
        <div
            style={{
                borderRadius: "8px",
                // overflow: "hidden",
                display: "flex",
                flexDirection: "column",
                margin: "16px auto",
            }}
        >
            {/* Header - Renderiza Imagem, Vídeo ou Fundo */}
            {data.type === "plan" || hasYoutube || data.image ? (
                <div
                    style={{
                        background: customApp('AvatarBackground'),
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        position: "relative",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        height: "200px",
                        background: !hasYoutube && !data.image
                            ? "radial-gradient(rgb(0, 78, 157) 0%, rgb(1, 30, 65) 100%)"
                            : "none",
                        overflow: "hidden",
                        backgroundImage: `url("${!ImagePreview ? defaultAvatar : ImagePreview}")`,
                    }}>
                    {hasYoutube ? (
                        <div
                            style={{
                                position: "relative",
                                width: "100%",
                                // paddingBottom: "56.25%",
                                // height: 0,
                                overflow: "hidden",
                            }}
                        >
                            {renderYoutubeOrVimeo()}
                        </div>
                    ) : data?.image && ImagePreview ? (
                        <img
                            src={data.image || defaultAvatar}
                            alt="Preview"
                            style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                            }}
                            onError={() => setImagePreview(false)}
                        />
                    ) : (
                        <img
                            src={MachenIco}
                            alt="Default"
                            style={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                                width: "50%",
                                objectFit: "contain",
                            }}
                        />
                    )}
                    {/* Código do Card */}
                    {data._cardCode && (
                        <div
                            style={{
                                position: "absolute",
                                bottom: 0,
                                right: 0,
                                padding: "2px 7px",
                                backgroundColor: "rgba(0,0,0,0.7)",
                                color: "#FFFFFF",
                                borderRadius: "10px 0px 0px 0px",
                                fontSize: 10,
                                zIndex: 2,
                            }}
                        >
                            # {data._cardCode}
                        </div>
                    )}
                </div>
            ) : null}

            {/* Body */}
            {data?.description && (
                <div style={{ lineHeight: "1.5", padding: "0px 5px 0px 0px", textAlign: "justify" }}>
                    {stripHTML(data.description).substr(0, 133)}
                </div>
            )}
            {/* KPI */}
            {data?.kpi_type && !session?.cards?.hide?.kpi && (
                <div style={{ width: "100%", position: 'relative', float: 'left' }}>
                    <KPIChange data={data} chartShow />
                </div>
            )}

            {
                (
                    (data.type !== 'plan'
                        &&
                        (

                            Object.keys(db).filter(fil => db[fil]
                                && db[fil].checklist
                                && db[fil]._parent === data._id
                            ).length > 0
                            && Object.keys(db).filter(fil => db[fil]
                                && db[fil].type === "step"
                                && db[fil]._parent === data._id
                            ).length === 0

                        )
                    )
                )
                &&
                <div style={{
                    position: 'relative',
                    width: 'calc(100%)',
                    top: '16px',
                    clear: 'both',
                    marginBottom: '16px'
                }}>
                    <div style={{
                        padding: '7px',
                        backgroundColor: 'rgba(0,0,0,0.05)',
                        fontSize: '16px',
                        borderTopLeftRadius: 7,
                        borderTopRightRadius: 7,
                    }}>{translate('$__list', 1)}</div>
                    <Checklist nodeId={data._id} db={"cards"} noDelete showProgress checklist />
                    <div style={{ clear: 'both' }}></div>
                </div>
            }
        </div>
    );
}

export default ExpandedCard;