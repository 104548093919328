import React, { useState } from "react";

// COMPONENTS
import CustomButton from "components/Buttons/custom";
import TextField from "components/TextField";
import Icon from "components/Icon";
import ProgressLinear from "components/Progress/linear";

//REDUX
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import * as reduxActions from "store/actions"

// FUNCTIONS
import { translate, customApp, appAlert } from "functions/";
import { action } from "functions/kanban";

function ApprovalComponent(props) {
    const { onAction } = props
    const [reason, setReason] = useState("");
    const [preloader, setPreloader] = useState(false);

    const handleApproval = (isApproved) => {
        const message = isApproved
            ? translate("$__confirmApproveItem", 1)
            : translate("$__confirmRejectItem", 1);

        appAlert({
            message,
            variant: "warning",
            persist: false,
            horizontal: "right",
            confirm: () => {
                action({ nodeId: props.nodeId, reason, approval: true, approved: isApproved });
                setPreloader(true);
                if (onAction) onAction();
            },
        });
    };

    return (
        <div style={{ width: "100%" }} onClick={(e) => e.stopPropagation()}>
            <div style={{ position: "relative", float: "right", width: "100%", padding: "7px" }}>
                <div
                    style={{
                        width: "100%",
                        backgroundColor: "#FFFFFF",
                        padding: 3,
                        borderRadius: 7,
                    }}
                >
                    <TextField
                        label={translate("$__ApprovalOrRejectReason", 1)}
                        variant={customApp("fieldVariant")}
                        name="reason"
                        multiline
                        rows={3}
                        value={reason}
                        onChange={(e) => setReason(e.target.value)}
                        placeholder={translate("$__ApprovalOrRejectReason", 1)}
                        adornment={
                            <span style={{ padding: "0 10px", cursor: "default" }}>
                                <Icon icon="toc" />
                            </span>
                        }
                    />
                </div>
                {preloader ? (
                    <ProgressLinear />
                ) : (
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <CustomButton
                            reverse
                            title={translate("$__indorse")}
                            text={translate("$__indorse")}
                            color="green"
                            textColor="green"
                            icon="approval"
                            onClick={() => handleApproval(true)}
                            size="25px"
                            style={{ padding: 12 }}
                        />
                        <CustomButton
                            reverse
                            title={translate("$__reject")}
                            text={translate("$__reject")}
                            color="red"
                            textColor="red"
                            icon="backspace"
                            onClick={() => handleApproval(false)}
                            size="25px"
                            style={{ padding: 12 }}
                        />
                    </div>
                )}
            </div>
        </div>
    );
}

const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch)

export default connect(null, mapDispatchToProps)(ApprovalComponent)
