// 
import AutoSizer from "react-virtualized-auto-sizer"
import React, { useLayoutEffect, useRef } from "react"
import { Droppable, Draggable } from "react-beautiful-dnd"
import { VariableSizeList } from "react-window"

//COMPONENTS
import Card from "components/Card";
import HeaderWithIcon from "components/Header/withIcon"
import IconButton from "components/CustomButtons/IconButton";
import HtmlTooltip from "components/Tooltip/html"

//REDUX
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import * as reduxActions from "store/actions"
import reduxStore from "store/"


//STYLE
import "./style.css"


//FUNCTIONS
import { customApp, translate, getAppAccess } from "functions"
import {
    getUserAccess,
} from "functions/cards"


function getStyle({ draggableStyle, virtualStyle, isDragging }) {
    const combined = {
        ...virtualStyle,
        ...draggableStyle
    }

    const grid = 8

    const result = {
        ...combined,
        height: isDragging ? combined.height : combined.height - grid,
        left: isDragging ? combined.left : combined.left + grid,
        width: isDragging
            ? draggableStyle.width
            : `calc(${combined.width} - ${grid * 2}px)`,
        marginBottom: grid
    }

    return result
}

const Item = (props) => {
    const { provided, item, style, isDragging } = props
    return (
        <div
            // innerRef={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={getStyle({
                draggableStyle: provided.draggableProps.style,
                virtualStyle: style,
                isDragging
            })}
            className={`item ${isDragging ? "is-dragging" : ""}`}
            ref={provided.innerRef}
        >
            <Card
                data={item}
                db={'cards'}
                isDragging={isDragging}
                manualExpanded={props.manualExpanded || false}
                onResize={(size, expanded) => {
                    if (props.onResize) props.onResize(size)
                }}
                onExpand={(expanded) => {
                    if (props.onExpand) props.onExpand(expanded)
                }}
            />
        </div>
    )
}

const ItemList = React.memo(function ItemList(props) {
    const { index, column, discount } = props
    const listRef = useRef()
    const rowHeights = useRef({})
    const rowExpandeds = useRef({});

    useLayoutEffect(() => {
        const list = listRef.current
        if (list) {
            list.scrollTo(0)
        }
    }, [index])

    function getRowHeight(id) {
        return rowHeights.current[id] + 7 || 67
    }

    function setRowHeight(index, size) {
        if (listRef?.current?.resetAfterIndex)
            listRef.current.resetAfterIndex(0)
        rowHeights.current = {
            ...rowHeights.current, [index]: size
        }
    }

    function setRowExpanded(index, expanded) {
        if (String(expanded) !== 'undefined' && String(expanded) !== 'null') {
            if (listRef?.current?.resetAfterIndex)
                listRef.current.resetAfterIndex(0);
            rowExpandeds.current = {
                ...rowExpandeds.current, [index]: expanded
            }
        }
    }

    const Row = (RowProps) => {
        const { data: items, index, style } = RowProps
        const item = items[index]

        if (!item) {
            return null
        }

        return (
            <Draggable draggableId={item._id} index={index} key={item._id}>
                {provided => <Item
                    innerRef={provided.innerRef}
                    provided={provided}
                    item={item}
                    style={style}
                    manualExpanded={props.cardsExpanded || rowExpandeds?.current[item._id] || false}
                    onResize={(size) => {
                        setRowHeight(item._id, size)
                    }}
                    onExpand={(expanded) => {
                        setRowExpanded(item._id, expanded)
                    }}
                />}
            </Draggable>
        )
    }

    return (
        <Droppable
            droppableId={column.id}
            mode="virtual"
            renderClone={(provided, snapshot, rubric) => {
                let card = column.items[rubric.source.index]
                return (
                    <Item innerRef={provided.innerRef}
                        provided={provided}
                        isDragging={snapshot.isDragging}
                        item={card}
                        manualExpanded={props.cardsExpanded || rowExpandeds?.current[card._id] || false}
                        snapshot={snapshot}

                    />
                )
            }}
        >
            {(provided, snapshot) => {
                const itemCount = snapshot.isUsingPlaceholder
                    ? column.items.length + 1
                    : column.items.length
                return (
                    <AutoSizer style={{
                        width: "100%",
                        height: `calc(100% - ${discount + 47}px)`,
                        overflowY: "auto",
                        position: "relative",
                    }}
                    >
                        {({ height, width }) => {
                            return (
                                <VariableSizeList
                                    width={279}
                                    height={height}
                                    itemCount={itemCount}
                                    itemSize={(index) => {
                                        return getRowHeight(column.items[index]?._id || null)
                                    }}
                                    itemData={column.items
                                        .sort((a, b) => {
                                            if (a.status === "completed" && b.status === "completed") {
                                                if (parseInt(a.completed_at) > parseInt(b.completed_at))
                                                    return -1
                                                if (parseInt(a.completed_at) < parseInt(b.completed_at))
                                                    return 1
                                                return 0
                                            }
                                            return 0
                                        })
                                    }
                                    className="task-list"
                                    ref={listRef}
                                    style={{
                                        ...props.style ? props.style : {},
                                        height: "100%"
                                    }}
                                    outerRef={provided.innerRef}
                                >
                                    {Row}
                                </VariableSizeList>
                            )
                        }}
                    </AutoSizer>
                )
            }}
        </Droppable >
    )
})

const Column = React.memo(function Column(props) {
    const { column, index } = props
    const { db, sideModule, control } = reduxStore.getState()
    const [discountHeight, setDiscountHeight] = React.useState(false)

    const AppAccess = getAppAccess()
    const CardAccess = getUserAccess(column.id)

    const permission = (
        (
            AppAccess.plan && AppAccess.planAdmin
        )
        || (
            parseInt(CardAccess) > 4
        )
    ) ? true : false

    const ColumnData = db.cards[column.id]
    const columnHeaderRef = React.useRef()

    React.useEffect(() => {
        const resizeObserver = new ResizeObserver(() => {
            setDiscountHeight(columnHeaderRef.current.clientHeight)
        });
        resizeObserver.observe(columnHeaderRef.current);
        return () => resizeObserver.disconnect();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [columnHeaderRef])


    let sumValues = 0
    if (ColumnData.showSumValues && column.items && column.items.length > 0 && column.items.filter(a => a.value).length > 0) {
        column.items.filter(a => a.value).forEach(a => {
            sumValues = sumValues + parseFloat(a.value)
        })
    }

    let currencySymbol = `R$`

    if (ColumnData._planId && db.cards[ColumnData._planId] && db.cards[ColumnData._planId].default_currency)
        currencySymbol = db.currency.filter(a =>
            String(a.value) === String(db.cards[ColumnData._planId].default_currency)
        )[0].symbol


    return (
        <Draggable draggableId={column.id} index={index}>
            {provided => (
                <div
                    className="column"
                    {...provided.draggableProps}
                    ref={provided.innerRef}
                >
                    <div
                        ref={columnHeaderRef}
                        {...provided.dragHandleProps}>
                        <HeaderWithIcon
                            title={`${column.name} (${column.items.length})`}
                            icon={column.icon || null}
                            color={customApp('menu')}
                            style={{
                                padding: '7px',
                                width: "100%"
                            }}
                            placeholder={translate("$__columnName")}
                            customButtons={permission ? [
                                {
                                    name: translate("$__columnSettings", 1),
                                    icon: "more_vert",
                                    color: customApp('menu'),
                                    onClick: () => {
                                        props.reduxFunction("ASYNC", "SET_MODULE", {
                                            ...sideModule,
                                            db: 'cards',
                                            id: column.id,
                                            module: "columnEdit",
                                            activeModule: "columnEdit",
                                            data: db.cards[column.id],
                                        })
                                    },
                                    show: 'hover',
                                    style: { right: "3px", position: "absolute", backgroundColor: "#e1e1e1" }
                                },
                            ] : []}
                        />
                        {ColumnData && ColumnData.description ?
                            <HtmlTooltip
                                arrow
                                title={(
                                    <React.Fragment>
                                        {ColumnData.description}
                                    </React.Fragment>
                                )
                                } >
                                <div style={{
                                    position: "relative",
                                    width: "100%",
                                    color: "gray",
                                    fontSize: 10,
                                    padding: "0px 15px",
                                    maxHeight: 68,
                                    textOverflow: "ellipsis",
                                    overflow: "hidden",
                                    paddingBottom: 15
                                }}>{ColumnData.description.substr(0, 133)}...</div>
                            </HtmlTooltip>
                            : <React.Fragment></React.Fragment>}
                        {ColumnData.showSumValues && sumValues > 0 ?
                            <div style={{
                                position: "relative",
                                width: "100%",
                                color: "black",
                                fontWeight: "bold",
                                fontSize: 12,
                                padding: "0px 15px",
                                paddingBottom: 15
                            }}>{currencySymbol} {sumValues}</div>
                            : <React.Fragment></React.Fragment>
                        }
                        <div style={{ clear: "both" }}></div>
                    </div>
                    <ItemList column={column} index={index} {...props} discount={discountHeight || 0} />
                    <div id="AgileKanbanColumn_304" style={{ position: 'relative', float: 'left', width: '100%', padding: '15px 7px' }}>
                        <IconButton
                            text={translate("$__add", '*')}
                            icon="add_box"
                            iconColor={customApp('medium')}
                            onClick={() => {
                                props.reduxFunction("ASYNC", "SET_CONTROL", {
                                    ...control,
                                    addCard: {
                                        _parent: column.id,
                                        rows: column.items.length
                                    },
                                })
                            }}
                        />
                    </div>
                </div>
            )}
        </Draggable>
    )
})

const mapStateToProps = (store, props) => ({
    cards: store.db.cards,
    cardsExpanded: store.db.cardsExpanded,
})

const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Column)